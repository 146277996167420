/* You can add global styles to this file, and also import other style files */
:root {
    /* --clr-text: #3d3d3d; */
    --clr-primary: #FD5F00;
    --clr-primary-light: #FF8A47;
    --clr-background: #F6F6E9;
    --clr-dark: #13334C;
    --clr-dark-secondary: #005792;
    --clr-dark-secondary-light: #2b7eb4;

    /* --ff-title: "Garamond", sans-serif;
    --ff-serif:"Crimson Text", serif;
    --ff-logo: "Lucida Handwriting", sans-serif; */
}

.primary {
    background-color: var(--clr-primary);
}

html, body {
    height: 100%;
}

body {
    margin: 0;
    background-color: var(--clr-background);
    font-family: Roboto, sans-serif;
}

*, *::before, *::after {
    box-sizing: border-box;
}